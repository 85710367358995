.page {
    padding: 20px 240px 66px 240px;
}

.title {
    font-family: PingFangSC-Semibold;
    font-size: 50px;
    color: #3F3F3F;
    letter-spacing: 0;
    font-weight: 600;
}

.selectGroup {
    margin: 70px 0 50px 0;
    display: flex;
    align-items: center;
}

.selectTitle {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #3F3F3F;
    letter-spacing: 0;
    font-weight: 400;
    margin-right: 25px;
}

.selector {
    width: 310px;
  }

.table {
    margin-top: 50px;
    :global {
        .ant-table-tbody{
            > tr:hover:not(.ant-table-expanded-row) > td, .ant-table-row-hover, .ant-table-row-hover>td {
                background-color:#ffffff!important;
         }
      }
      .ant-table-fixed{
       .ant-table-row-hover, .ant-table-row-hover>td {
            background-color: #ffffff!important;
        }
       }
    }
}