.wrapper {
    padding-left: 40px;
    // width: 100%;

    .title {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 10px;
        font-size: 26px;
        color: rgba(124, 124, 124, 1);

    }

    .list {
    }

    
}

.item {
    display: flex;
    width: 100%;
    height: 120px;
    align-items: center;
    justify-content: space-between;
    border-radius: 8px;
    background: rgba(249, 249, 249, 1);
    border: 1px solid rgba(151, 151, 151, 0.5);
    margin-bottom: 10px;

    .index {
        width: 58px;
        text-align: center;
        background: rgba(179, 227, 193, 1);
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        color: rgba(255, 255, 255, 1);
        font-size: 40px;
        font-weight: 600;
        
    }

    .challenge {
        margin-left: 30px;
        margin-right: 23px;
    }

    .strategy {
        margin-right: 20px;
    }
}

.btn {
    color: rgba(47, 178, 85, 1);
}

.add {
    display: flex;
    justify-content: flex-end;
    margin-top: 22px;
}

.delete {
    position: relative;
    bottom: 40px;
    margin-right: 6px;
    cursor: pointer;
}

.notification {
    height: 27px;
    width: 26px;   
    margin-left: 4px;
    position: relative;
    top: 2px;
    
}