.page {
    background-color: #ffffff;
    min-height: 100vh;

    .wrapper {
        padding: 0 160px 350px 160px;

    }

    .editorialTitle {
        margin-top: 50px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(70, 70, 70, 1)
    }

    .title {
        font-size: 40px;
        font-weight: 600;
        color: rgba(70, 70, 70, 1);
        margin-top: 30px;
    }

    .subTitle {
        margin-left: 20px;
        font-weight: 600;
        font-size: 20px;
        color: rgba(70, 70, 70, 1);
    }
}


.monitor {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;

    .graph {
        height: 300px;
        width: 370px;
    }

    

    .monitorTitle {
        font-size: 20px;

        color: rgba(105, 105, 105, 1)
    }
}

.taskWrapper {
    display: flex;
    overflow-x: scroll;
    margin-top: 16px;
    padding-bottom: 16px;

    .addTask {
        cursor: pointer;
        width: 344px;
        height: 238px;
        color: rgba(47, 178, 85, 1);
        border: 3px dashed rgba(47, 178, 85, 1);
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        flex-shrink: 0;
    }

    .taskImg {
        width: 94px;
        height: 94px;
        margin-bottom: 25px;
    }

    .taskDetail {
        width: 344px;
        height: 238px;
        border: 1px solid rgba(151, 151, 151, 0.5);
        border-left: 8px solid rgba(151, 151, 151, 0.5);
        border-radius: 8px;
        margin-left: 40px;
        flex-shrink: 0;
        position: relative;
    }

    .completeIcon {
        width: 70px;
        height: 60px;
        position: absolute;
        right: 12px;
        top: 10px;
    }

    .taskDate {
        font-size: 12px;
        color: rgba(125, 125, 125, 1);
        margin-left: 25px;
        margin-top: 16px;
    }

    .taskTitle {
        
        font-size: 22px;
        font-weight: 600;
        margin-top: 16px;
        margin-left: 25px;
    }

    .titleContent {
        font-size: 18px;
        margin-left: 25px;
        margin-top: 3px;
        height: 76px;
        overflow: hidden;
    }

    .btnWrapper {
        display: flex;
        justify-content: space-evenly;
        font-size: 16px;
        position: relative;
        top: 10px;

        .leftBtn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 145px;
            height: 47px;
            border-radius: 8px;
            color: rgba(86, 88, 117, 1);
            border: 1px solid rgba(86, 88, 117, 1);
        }

        .rightBtn {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 145px;
            height: 47px;
            border-radius: 8px;
            color: rgba(47, 178, 85, 1);
            border: 1px solid rgba(47, 178, 85, 1);
        }

        .disabled {
            color: #999999;
            border: 1px solid #999999;
        }
        
    }

}

.editorialWrapper {
    .editorSelector {
        margin: 45px 0;
    }
    .editorBanner {
        border: 6px solid rgba(47, 178, 85, 1);
        margin-bottom: -1px;
    }
}
:global(.ant-table-thead > tr > th) {
    border-radius: 0 !important;
}
:global(.ant-table-container) {
    border-radius: 0 !important;
}
.tableRanking {
    display: flex;
    align-items: center;
    // justify-content: center;
    font-size: 30px;
    font-weight: 400;
    color: rgba(60, 60, 60, 1);
    .tableLike {
        display: flex;
        align-items: center;
        .tableLikeIcon {
            width: 33px;
            // height: 31px;
            margin-right: 15px;
            img {
                width: 100%;
            }
        }
    }
    .tableComment {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .tableCommentIcon {
            width: 36px;
            height: 33px;
            margin-right: 15px;
            img {
                width: 100%;
            }
        }
    }
    .tableView {
        display: flex;
        align-items: center;
        margin-left: 30px;
        .tableViewIcon {
            width: 40px;
            // height: 27px;
            margin-right: 15px;
            img {
                width: 100%;
            }
        }
    }
    
}
.tableCommented {
    display: flex;
    justify-content: center;
    align-items: center;
    .tableCommentedIcon {
        width: 45px;
        img {
            width: 100%;
        }
    }
}

.monitorWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 50px;
}