.chartOne {
    height: 100%;
    width: 100%;
}

.chartThree {
    height: 100%;
    width: 100%;
    position: relative;
    top: 30px;
}