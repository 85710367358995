.body {
  display: flex;
  justify-content: center;
  
  .table {
    width: 1600px;
    margin-top: 40px;
    margin-bottom: 40px;
    color: rgba(19, 20, 21, 1);
    font-size: 20px;
    border: 1px solid #979797a7;
    tr {
      height: 72px;
    }
    th {
      font-weight: 500;
      background: #FFEBDE;
    }
  }

  tr:nth-child(even) {
    background: #FFFFFF;
  }

  tr:nth-child(odd) {
    background: #FFF7EF;
  }

  .red {
    color: rgba(255, 29, 29, 1);
  }
}