
.modalClose {
    width: 56px;
    height: 56px;
    img {
        width: 100%;
    }
}

.closeIcon {
    white-space: nowrap;
    position: relative;
    right: 168px;
}
.modal {
    padding: 0px 20px 0px 50px;
    .modalTitle {
        font-size: 40px;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
        text-align: center;
    }
    .modalContent {
        margin-top: 70px;
        font-size: 30px;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
        .modalAnswerRate {
            display: flex;
            flex-direction: column;
            // align-items: center;
            font-size: 24px;
            font-weight: 500;
            color: rgba(43, 43, 43, 1);
            margin: 20px 0 50px 28px;
            .modalRate {
                margin-left: 20px;
                margin-right: 20px;
            }

            .red {
                color: rgba(255, 29, 29, 1);
            }
            .green {
                color: rgba(47, 178, 85, 1);
            }
            .correctAnswer {
                margin-top: 8px;
            }
        }
        .modalAnswerRadio {
            margin: 20px 0;
        }
        .modalInput {
                width: 1000px;
                height: 165px;
                border: 1px solid rgba(151, 151, 151, 0.5);
                font-size: 20px;
                padding: 16px;
                color: #898989;
                resize: none;
                background-color: #FFFFFF;
                margin-top: 20px;
            input:focus {
                outline: none;
            }
            textarea:focus {
                outline: none;
            }
        }
    }
    .modalBottom {
        display: flex;
        justify-content: center;
        margin-top: 120px;
        margin-bottom: 40px;
    }

    .submitBtn {
        width: 350px;
    }
}