body {
    background-color: rgba(247, 247, 247, 1);
}

.container {
    padding: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;


    .right {
        width: 100%;
        height: 1000px;
       
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #979797;


        .wrapperTitle {
            border-radius: 8px;
            padding: 23px 0px;
            background: rgba(224, 224, 224, 1);
            border-bottom: 1px solid rgba(151, 151, 151, 0.5);
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            color: rgba(76, 76, 76, 1);
        }

        .wrapperContent {
            font-size: 30px;
            line-height: 44px;
            height: 854px;
            padding: 25px 66px 30px 50px;
            overflow-y: scroll;
        }
       
    }

    .title {
        margin-left: 75px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(47, 178, 85, 1);
    }

    
    .footerRight {
        width: 320px;
        // position: relative;
        // bottom: 100px;
        margin-top: 30px;
        cursor: pointer;
        padding: 16px;
        border-radius: 8px;
        background: rgba(47, 178, 85, 1);
        font-size: 25px;
        font-weight: 400;
        color: rgba(255, 255, 255, 1);
        .text {
            text-align: center;
        }

    }
}
