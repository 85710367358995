.body {
  margin-top: 0px;
  background-color:  rgba(247, 247, 247, 1);
  display: flex;
  flex-wrap: nowrap;
  padding: 54px 84px 50px 92px;
  align-items: center;
  justify-content: space-between;
}


.left {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.leftLogo {
  width: 417px;
  margin-right: 70px;
}

.title {
  /* line-height: 75px; */
  font-size: 30px;
  padding: 0px 68px;
  border-left: 1px solid rgba(47, 178, 85, 1);
  font-size: 38px;
  font-weight: 500;
  color: rgba(47, 178, 85, 1);
}

.img {
  width: 61.02px;
  height: 58px;
  margin-right: 18px;
}

.normalTab, .currentTab {
  font-family: PingFangSC-Regular;
  font-size: 26px;
  color: #9B9B9B;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: 400;
  margin: 0 30px 0 30px;
  text-decoration: none;
  cursor: pointer;
}

.firstTab {
  font-family: PingFangSC-Regular;
  font-size: 26px;
  color: #999999;
  letter-spacing: 0;
  line-height: 28px;
  font-weight: bold;
  margin: 0 30px 0 30px;
  text-decoration: none;
  cursor: pointer;
}

.menuDivider {
  height: 40px;
  border: 0.6px solid #D3D3D3;
  margin: 0 10px;
  cursor: default !important;
  
}

.tabTitle {
  font-family: PingFangSC-Regular;
  font-size: 30px;
  letter-spacing: 1px;
  text-decoration: none;
  color: #9B9B9B;
  margin: 0 60px 0 0;
}

.currentTab {
  color: #2FB255;
}

.right {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  
}

.right > div, .left > div {
  cursor: pointer;
}



.buttonGroup {
  display: flex;
  flex-wrap: nowrap;
  /* margin-left: 300px; */
}

.button {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 20px;
  height: 55px;
  align-items: center;
  justify-content: center;
  margin-right: 22px;
  background: #EAEBEF;
  border-radius: 8px;
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #595959;
  letter-spacing: 0;
  font-weight: 400;
  cursor: pointer;
  width: 250px;
}

.writing, .battle, .chat {
  margin-right: 20px;
}

.writing {
  width: 20px;
}

.battle {
  width: 31px;
}

.chat {
  width: 32px;
}

.coin {
  display: flex;
  flex-wrap: nowrap;
  /* width: 147px; */
  padding: 0 27px 0 9px;
  height: 50px;
  margin-left: 20px;
  align-items: center;
  background: #FCE1C3;
  border: 1px solid #FBA148;
  border-radius: 42px;
  font-family: PingFangSC-Regular;
  font-size: 25px;
  color: #D67132;
  letter-spacing: 0;
  font-weight: 400;
}

.coinIcon {
  width: 39px;
  height: 40px;
  margin-right: 21px;
}

.userInfo {
  display: flex;
  align-items: center;
}

.userAvatar {
  width: 43px;
  height: 43px;
  margin: 0 28px 0 60px;
  border-radius: 100%;
}

.pop:global.ant-popover-content {
  background: #FFFFFF;
  border: 1px solid #DEDEDE;
  box-shadow: 0 2px 10px 0 rgba(0,0,0,0.16);
  border-radius: 8px;
  height: 108px;
}

.myAccount, .logOut {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: #343434;
  letter-spacing: 0;
  line-height: 60px;
  font-weight: 400;
  width: 180px;
  margin-left: 17px;
  cursor: pointer;
  white-space: nowrap;
}

.divider {
  border: 0.1px solid #D3D3D3;
  width: 186px;
}

.userName {
  font-family: PingFangSC-Regular;
  font-size: 20px;
  color: rgba(26, 26, 26, 1);
  letter-spacing: 0;
  font-weight: 400;
  margin-left: 50px;
}

.menu {
  font-size: 20px;
  padding: 0 50px;
  font-weight: 400;
  color: rgba(26, 26, 26, 1);
  border-right: 1px solid rgba(126, 126, 126, 0.5);
}


.readingTabs {
  display: flex;
  align-items: center;

  .readingTabIcon {
    margin-left: 12px;
  }

  .divideAc {
    width: 48px;
    height: 1px;
    border-bottom: 1px solid #2FB255;
  }

  .divide {
    width: 48px;
    height: 1px;
    border-bottom: 1px solid #C4C4C4;
  }

  .tabItem {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    border-radius: 27px;
    border: 1px solid #C4C4C4;
    padding: 6px;
    font-size: 25px;
    font-weight: 600;
    color: #C4C4C4;

    img {
      width: 43px;
      height: 43px;
    }

    .tit {
      margin-left: 10px;
      margin-right: 10px;
    }
  }

  .tabItemAc {
    background: #F0FFF4;
    border: 1px solid #2FB255;
    color: #2FB255;
  }

}