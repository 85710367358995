.group {
    display: flex;
    height: 171px;
    width: 946px;
    border-radius: 8px;
    border: 1px solid rgba(151, 151, 151, 0.5);
    position: relative;

    & > div {
        flex: 1;
        padding: 23px 44px;
        
        // justify-self: center;

        &:first-child {
            border-right: 1px solid rgba(151, 151, 151, 0.5);
        }

        .title {
            display: flex;
            align-items: center;
            font-size: 35px;
        }
    }

    .dateArrow {
        width: 56px;
        height: 56px;
        align-self: center;
        position: absolute;
        left: 446px;
    }

    .date {
        width: 35px;
        height: 35px;
        margin-right: 14px;
    }

    .dateTime {
        font-size: 30px;
        margin-top: 16px;
        font-weight: 500;
    }
}