.container {
    width: 100%;
    display: flex;

    .containerLeft {
        width: 58%;
        min-height: 100vh;
        background: #ffffff;
        padding: 60px 80px 120px 140px;
        .leftTitle {
            font-size: 40px;
            font-weight: 600;
            color: rgba(47, 178, 85, 1);
        }
        .leftSubTitle {
            font-size: 30px;
            font-weight: 400;
            margin: 10px 0 20px 0;
            color: rgba(60, 60, 60, 1);
        }
        .articleContent, .summaryContent {
            padding: 50px 40px;
            font-size: 30px;
            // font-weight: 600;
            line-height: 40px;
            color: rgba(76, 76, 76, 1);
            white-space: pre-wrap;
            // word-wrap: break-word;
            border: 1px solid #979797;
            border-radius: 8px;
            font-family: 'Times New Roman', Times, serif;
            // height: 770px;
            // overflow-y: scroll;
        }
        .summaryContent {
            // height: 170px;
        }
    }
    .containerRight {
        width: 42%;
        min-height: 100vh;
        padding: 130px 90px 90px 90px;
        // background: #EEEEEE;
        .recommendedContainer {
            border-radius: 8px;
            background: #FFFFFF;
            border: 1px solid #979797;
            padding: 30px;
            .recommendedInfo {
                font-size: 24px;
                font-weight: 400;
                color: rgba(76, 76, 76, 1);
                height: 240px;
                white-space: pre-wrap;
                word-wrap: break-word;
                overflow-y: scroll;
                margin-left: 10px;
            }
            .recommendedTitle {
                margin-bottom: 6px;
                font-weight: 600;
            }
            .recommendedContent {
                margin-bottom: 16px;
            }
            .recommendedDivider {
                border: 0.1px solid #979797;
                margin: 20px 0 20px 0
            }
            .recommendedFooter {
                display: flex;
                align-items: center;
                justify-content: space-between;
                .footerLeft {
                    width: 625px;
                    font-size: 20px;
                    font-weight: 400;
                    color: rgba(76, 76, 76, 1);
                }
                .footerRight {
                    display: flex;
                    align-items: center;
                    font-size: 20px;
                    font-weight: 400;
                    color: rgba(251, 163, 68, 1);
                    .likeIcon {
                        width: 56px;
                        height: 56px;
                        margin: 0 15px 0 60px;
                        cursor: pointer;
                        img {
                            width: 100%;
                        }
                    }
                    .likeCount {
                        color: rgba(76, 76, 76, 1);
                    }
                    .recommendedCoinIcon {
                        width: 35px;
                        height: 35px;
                        margin-left: 10px;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
        .commentContainer {
            margin-top: 30px;
            .commentTitle {
                display: flex;
                align-items: flex-end;
                justify-content: space-between;
                .commentTitleLeft {
                    display: flex;
                    align-items: center;
                    font-size: 30px;
                    font-weight: 600;
                    color: rgba(76, 76, 76, 1);
                    .commentIcon {
                        width: 34px;
                        height: 32px;
                        margin-right: 20px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .commentTitleRight {
                    font-size: 20px;
                    font-weight: 400;
                    color: rgba(76, 76, 76, 1);
                }
            }
            .commentInputContainer {
                border-radius: 8px;
                border: 1px solid rgba(151, 151, 151, 1);
                background: #FFFFFF;
                margin-top: 20px;
                padding: 40px 30px 25px 50px;
                .commentInput {
                    font-size: 25px;
                    width: 100%;
                    height: 300px;
                    resize: none;
                    border: none;
                    border-radius: 8px;
                
                }
                input:focus {
                    outline: none;
                }
                .commentInputBtn {
                    display: flex;
                    justify-content: flex-end;
                    .submitBtn {
                        height: 80px;
                        border-radius: 8px;
                        background: rgba(47, 178, 85, 1);
                        display: flex;
                        align-items: center;
                        font-size: 25px;
                        font-weight: 400;
                        color: rgba(255, 255, 255, 1);
                        padding: 0 20px 0 30px;
                        cursor: pointer;
                        .buttonDivider {
                            margin: 0 10px;
                            border: 0.1px solid rgba(44, 163, 79, 1);
                            height: 95%;
                        }
                        .buttonCoin {
                            margin-left: 5px;
                            width: 35px;
                            height: 35px;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .commentList {
                // height: 75vh;
                // overflow-y: scroll;
                margin-top: 20px;
                .commentItem {
                    margin-bottom: 20px;
                    padding: 30px 30px 35px 60px;
                    border-radius: 8px;
                    background: rgba(255, 255, 255, 1);
                    border: 1px solid rgba(151, 151, 151, 1);
                    .commentItemtitle {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-end;
                        .commentName {
                            font-size: 25px;
                            font-weight: 600;
                            color: rgba(76, 76, 76, 1);
                        }
                        .commentDate {
                            font-size: 12px;
                            font-weight: 400;
                            color: rgba(163, 163, 163, 1);
                        }
                    }
                    .commentContent {
                        margin-top: 15px;
                        font-size: 24px;
                        font-weight: 400;
                        color: rgba(76, 76, 76, 1);
                        line-height: 1.5;
                    }
                }
            }
        }
    }
}