.body {
    /* height: 1183px; */
    display: flex;
    flex-wrap: nowrap;
    background: #FFFFFF;
    border: 1px solid rgba(151, 151, 151, 0.5);
    border-radius: 8px;
    position: relative;
  }
  
  .close {
    width: 56px;
    position: absolute;
    top: 26px;
    right: 26px;
    cursor: pointer;
  }
  
  .left {
    width: 825px;
    padding: 149px 0 0 86px;
    color: #222222;
    border-radius: 36px 0 0 36px;
  }
  
  .leftTitle {
    font-family: Helvetica;
    font-size: 40px;
    letter-spacing: 0;
    line-height: 77px;
    font-weight: 400;
  }

  .detailTitle {
    font-weight: 600;
  }
  
  .leftContent {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    letter-spacing: 0;
    font-weight: 400;
    margin-top: 48px;
  }
  
  .hasDetail, .noDetail {
    margin-bottom: 50px;
  }
  
  .noDetail {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
  }
  
  .detail {
    display: flex;
    align-items: center;
  }
  
  .leftCoin {
    width: 27px;
    height: 27px;
    margin: 0 8px 0 12px;
  }

  .titleCoin {
    width: 69px;
    height: 70px;
    rotate: 20deg;
    position: relative;
    top: 10px;
  }
  
  .leftCoinNums {
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #F8632E;
    letter-spacing: 0;
    font-weight: 400;
  }

  .right {
    width: 488px;
    /* flex-direction: column; */
    padding: 66px 0 0 60px;
    position: relative;
    /* display: flex; */
    /* justify-content: center; */
  }
  
  .leaderboard {
    width: 764px;
  }

  .tabs {
    margin-top: 10px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    height: 60px;
    align-items: center;
    background-color: rgba(255, 162, 53, 0.67);
    font-size: 16px;
    font-weight: 500;
    color: #ffffff;
    /* margin: 50px 0 60px 100px; */

    & > div {
      margin-right: 50px;
    }
  }

  .allClasses {
    margin-left: 83px;
  }

  .currentTab {
    font-family: PingFangSC-Semibold;
    font-size: 50px;
    color: #A84522;
    letter-spacing: 0;
    font-weight: 600;
    text-decoration: underline;
    cursor: pointer;
  }

  .anotherTab {
    font-family: PingFangSC-Semibold;
    font-size: 50px;
    color: #BDBDBD;
    letter-spacing: 0;
    font-weight: 600;
    cursor: pointer;
  }


  .rightContent {
    /* height: 500px; */
    margin-bottom: 60px;
    height: 700px;
    overflow: scroll;
  }

  .rankItem {
    display: flex;
    // justify-content: space-evenly;
    flex-wrap: nowrap;
    align-items: center;
    height: 50px;
  }

  .rankItem:nth-child(even) {
    background: rgba(255, 184, 140, 0.17);
  }


  .avatar {
      height: 70px;
      width: 70px;
      border-radius: 35px;
      margin: 0 25px 0 25px;
      border-radius: 100%;
  }
  
  .rankIndex {
    font-family: PingFangSC-Regular;
    font-size: 18px;
    color: #969696;
    letter-spacing: 0;
    font-weight: 400;
    // margin-left: 60px;
    width: 140px;
    text-align: center;
  }

  .rankName {
    font-size: 16px;
    color: rgba(52, 52, 52, 1);
    width: 200px;
    text-align: center;
  }

  .rankCoin {
    font-size: 16px;
    color: rgba(52, 52, 52, 1);
    // margin-left: 20px;
    display: flex;
    justify-content: center;
    text-align: center;
    width: 120px;
    align-items: center;
  }

  .rankImg {
    width: 20px;
    margin-left: 60px;
    margin-right: 60px;
  }

  .title {
    font-size: 40px;
    font-weight: 600;
  }
  .rightTitle {
    text-align: center;
    font-size: 32px;
    font-weight: 600;
    color: rgba(246, 177, 80, 1);
  }

  .deco {
    width: 600px;
    position: absolute;
    top: 0px;
    left: 0px;
  }