body {
    background-color: rgba(247, 247, 247, 1);
}
.container {
    padding: 60px 125px 80px 125px;
    .title {
        margin-left: 15px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(47, 178, 85, 1);
    }
    .displayContainer {
        margin: 60px 0 25px 0;
        
        width: 100%;
        display: flex;
        overflow-x: scroll;
        overflow-y: hidden;

        .weeklyTable {
            flex-shrink: 0;
            width: 1390px;
            height: 1050px;
            margin-right: 30px;
            border-radius: 8px;
            background-color: #ffffff;
            border: 1px solid rgba(151, 151, 151, 1);
            display: flex;
            .plan {
                width: 50%;
                overflow-y: scroll;
                padding: 60px 30px 30px 70px;
                .planTitle {
                    display: flex;
                    align-items: flex-end;
                    margin-bottom: 55px;
                    font-size: 40px;
                    font-weight: 600;
                    color: rgba(79, 79, 79, 1);
                    .planTitleIcon {
                        width: 55px;
                        margin-right: 40px;
                        // height: 53px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .planContent {
                    font-size: 26px;
                    font-weight: 400;
                    color: rgba(79, 79, 79, 1);
                    .planItem {
                        margin-bottom: 30px;
                        .planTextContent {
                            white-space: pre-wrap;
                            word-wrap: break-word;
                        }
                        .planSubTitle {
                            margin-bottom: 10px;
                            font-weight: 600;
                        }
                    }
                }
            }
            .achievement {
                width: 50%;
                padding: 60px 30px 30px 70px;
                border-left: 1px solid rgba(151, 151, 151, 1);
                .achievementTitle {
                    display: flex;
                    align-items: center;
                    margin-bottom: 55px;
                    font-size: 40px;
                    font-weight: 600;
                    color: rgba(79, 79, 79, 1);
                    .achievementTitleIcon {
                        width: 55px;
                        margin-right: 20px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .achievementContent {
                    font-size: 26px;
                    font-weight: 400;
                    color: rgba(79, 79, 79, 1);
                    .showContent {
                        .achievementItem {
                            margin-bottom: 30px;
                            .achievementTextContent {
                                white-space: pre-wrap;
                                word-wrap: break-word;
                            }

                            .planSubTitle {
                                margin-bottom: 10px;
                                font-weight: 600;
                            }
                        }
                    }
                    .defaultPage {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        font-size: 20px;
                        font-weight: 400;
                        color: rgba(163, 163, 163, 1);
                        .defaultIcon {
                            width: 161.5px;
                            margin: 200px 0 40px 0;
                            img {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
        .planButtonContainer {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 190px;
            .planButton {
                width: 655px;
                padding: 20px 0;
                border-radius: 8px;
                background: rgba(47, 178, 85, 1);
                border: 1px solid rgba(151, 151, 151, 1);
                display: flex;
                align-items: center;
                font-size: 40px;
                font-weight: 600;
                color: rgba(255, 255, 255, 1);
                cursor: pointer;
                .planButtonIcon {
                    width: 56px;
                    margin: 0 40px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
        
    }
    .footer {
        margin-top: 20px;
        font-size: 26px;
        font-weight: 400;
        color: rgba(79, 79, 79, 1);
        margin-left: 70px;
    }
}