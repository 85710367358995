.cardWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

}

.btnWrapper {
    display: flex;
    justify-content: center;

    .btn {
        background-color: #2FB255;
        color: #FFFFFF;
        width: 309px;
        height: 80px;
    }

    .disable {
        background-color: #c7ebd3;
    }
}

.assessment {
    display: flex;
    position: relative;
    bottom: 60px;
    width: 400px;
    margin-left: 140px;
    cursor: pointer;

    img {
        width: 30px;
    }
    .content {
        margin-left: 16px;
        font-family: PingFang-SC, PingFang-SC;
        font-weight: 400;
        font-size: 32px;
        color: #131415;
        line-height: 45px;
        text-align: right;
        font-style: normal;
    }
}


.modalClose {
    width: 56px;
    img {
        width: 100%;
    }
}
.modal {
    padding: 65px 75px 125px 75px;
    .modalTitle {
        font-size: 40px;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
        text-align: center;
    }
    .modalSubTitle {
        font-size: 24px;
        margin-bottom: 20px;
        text-align: center;
        color: #898989;
    }
    .modalFooter {
        font-size: 30px;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
        text-align: center;
        margin-top: 25px;
    }
}

:global(.ant-table-thead > tr > th) {
    text-align: center !important;
    background: #E7E7E7 !important;
}
