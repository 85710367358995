.page {
    padding: 60px 120px;
    .title {
        margin-left: 75px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(47, 178, 85, 1);
    }

    .container {
        display: flex;
        margin-top: 38px;

        .left, .middle, .right {
            background-color: #ffffff;
            border-radius: 8px;
            border: 1px solid rgba(151, 151, 151, 0.5);
        }

        .left {
            width: 546px;
            margin-right: 26px;
            padding: 20px;
            height: 1060px;
            position: relative;
            flex-shrink: 0;

            .leftTitle {
                // margin-left: 36px;
                font-size: 30px;
                margin-left: 16px;
                margin-top: 12px;
                font-weight: 600;
               
                color: rgba(55, 55, 55, 1);
            }

            .noteWrapper {
                background-color: #FFEDCF;
                padding: 16px 40px;
                width: 436px;
                position: absolute;
                bottom: 20px;
                border-radius: 8px;

            }

            .note {
                border: none;
                height: 380px;
                width: 100%;
                background-color: #FFEDCF;
                resize: none;
                line-height: 50px;
                font-size: 30px;
                background-attachment: local;
                background-image:
                    // linear-gradient(to right, white 10px, transparent 10px),
                    // linear-gradient(to left, white 10px, transparent 10px),
                    repeating-linear-gradient(#FFEDCF, #FFEDCF 50px, #F8DCBC 50px, #F8DCBC 51px, white 51px);
            }

            .strategy {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-top: 20px;
            }
        }

        .middle {
            font-family: 'Times New Roman', Times, serif;
            width: 1112px;
            margin-right: 39px;
            overflow-y: scroll;
            white-space: pre-wrap;
            padding: 40px;
            height: 1020px;
            font-size: 30px;
            line-height: 40px;
            flex-grow: 1;

            b {
                // font-weight: normal;
                background-color: yellow;
            }
        }

        .right {
            width: 618px;
            height: 1100px;
            flex-shrink: 0;
        }
    }
}


.strategyTitle {
    font-size: 30px;
    font-weight: 500;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-left: 6px;
}

.strategyItem {
    display: flex;
    .itemTitle {
        width: 240px;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 20px;
        margin-left: 50px;

    }
}

.btn {
    color:rgba(47, 178, 85, 1);
}
.bottom {
    margin-top: 30px;

    display: flex;
    justify-content: center;
    .submitBtn {
        width: 300px;
    }
}


.notification {
    height: 27px;
    width: 26px;   
    margin-left: 4px;
    position: relative;
    top: 2px;
    
}