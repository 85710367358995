body {
    background-color: rgba(247, 247, 247, 1);
}

.main {
    // position: fixed; 
    // position: static;
    position: relative;
    z-index: -1;

    .bgy {
        position: absolute;
        left: 0;
        top: 480px;
        z-index: 0;

        width: 676px;
        height: 706px;
        background: rgba(253,235,194,0.3);
        filter: blur(35px);
        border-radius: 400px;
    }

    .bgg {
        position: absolute;
        right: 0;
        top: 40px;
        z-index: 0;
        width: 1135px;
        height: 899px;
        background: rgba(220,237,226,0.6);
        filter: blur(39px);
        border-radius: 600px;
    }

    .slogan {
        position: absolute;
        width: 600px;
        right: 100px;
        top: 900px;
    }
}