
body {
  /* background-color: #F3F5F9; */
}

.logIn {
  display: flex;
  flex-wrap: nowrap;
}

.left {
  width: 55%;
  padding-left: 103px;
  padding-top: 100px;
}

.leftLogo {
  width: 417px;
  margin-bottom: 20px;
}

.coin {
  width: 35px;
  height: 35px;
  margin-left: 5px;
  margin-right: 5px;
  position: relative;
  top: 5px;
}

.loginHint {
  margin-top: 80px;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0px;
  line-height: 21.09px;
  color: rgba(88, 88, 88, 1);
}


.leftContent {
  font-family: PingFangSC-Regular;
  font-size: 22px;
  color: #585858;
  /* width: 1056px; */
  margin-right: 150px;
}

.contentBold {
  font-weight: 600;
  font-size: 25px;
}

.bold {
    font-family: PingFangSC-Semibold;
}

.right {
  width: 50%;
  flex: 1;
  position: relative;
}

.loginGuide {
  width: 238.77px;
  transform: rotate(1deg);
  position: relative;
  left: 550px;
  top: 100px;
}

.rightBox {
  width: 753px;
  margin-top: 243px;
  padding:103px 58px 89px 58px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0 3px 20px 10px rgba(0,0,0,0.09);
  border-radius: 15px;
}

.rightTitle {
  /* font-family: Arial-BoldMT; */
  font-size: 50px;
  font-weight: 400;
  color: rgba(88, 88, 88, 1);
}

.account, .password {
  display: flex;
  flex-flow: row nowrap;
  background: #F5F5F5;
  border: 1px solid #D7D7D7;
  border-radius: 15px;
}

.account {
  margin-top: 88px;
}

.password {
  margin-top: 46px;
}

.accountImg, .pswImg {
  margin: 25px 60px 28px 27px;
}

.accountImg {
  height: 45px;
  width: 48px;
  margin: 25px 60px 28px 27px;
}

.pswImg {
  height: 45px;
  width: 42px;
  margin: 25px 62px 28px 27px;
}

.input {
  width: 484px;
  background-color: #F5F5F5;
  border-style: none;
  font-family: ArialMT;
  font-size: 35px;
  color: #6D7278;
}

input:focus {
  outline: none;
}

.rightTerms {
  margin-top: 36px;
  font-family: ArialMT;
  font-size: 25px;
  color: #6D7278;
  display: flex;
  align-items: center;
}

.termsLink {
  font-family: Arial-BoldMT;
  font-size: 25px;
  color: #0091FF;
  text-decoration: underline;
  margin-left: 5px;
}

.checkbox {
  border-style: none;
  margin-right: 10px;
}

.buttonNormal, .buttonDisable {
  margin: 61px auto 0px auto;
  width: 100%;
  line-height: 98px;
  border-radius: 10px;
  font-family: PingFangSC-Regular;
  font-size: 40px;
  text-align: center;
  cursor: pointer;
  color: #FFFFFF;
}

.buttonNormal {
  background-color: #2FB255;
}

.buttonDisable {
  background: #BAE4C2;
}

.link {
  position: absolute;
  top: 1158px;
  right: 257px;
  font-family: Arial-ItalicMT;
  font-size: 30px;
  color: #2E7AE5;
  letter-spacing: 0;
  line-height: 40px;
  /* font-weight: 400; */
  text-decoration: underline;
}

.xxLinks {
  display: flex;
  justify-content: space-between;
}
.xxLink {
  font-size: 35px;
  font-family: Arial-ItalicMT;
  font-size: 30px;
  color: #2E7AE5;
  letter-spacing: 0;
}

.underLink {
  text-decoration: underline;
}

.express {
  color: rgba(88, 88, 88, 1);

  .title {
    margin-top: 22px;
    font-size: 24px;
    font-weight: 600;
   
    &::before {
      content: "●";
      margin-right: 5px;
      color: #C5C5C5;
    }
    
  }

  .exp {
    margin-left: 36px;
    font-size: 22px;
    font-weight: 400;
  }

  .subTitle {
    margin-top: 13px;
    display: flex;

    &::before {
      content: "";
      width: 10px;
      height: 10px;
      flex-shrink: 0;
      position: relative;
      top: 10px;
      border-radius: 50%;
      background-color: #C5C5C5;
      display: inline-block;
      margin-left: 12px;
      margin-right: 12px;
      color: #C5C5C5;
    }
  }
}


.beian {
  width: 100%;
  text-align: center;
  background-color: #2FB255;
  position: fixed;
  bottom: 0;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;

  .beianLink {
    color: #fff;
    font-size: 22px;
    margin-right: 40px;
    margin-left: 4px;
  }
}

