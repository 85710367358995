body {
    background-color: rgba(247, 247, 247, 1);
}

.container {
    padding: 20px;
    display: flex;
    justify-content: center;

    .left {
        width: 1200px;
        height: 1100px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #979797;
        padding: 0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;

        .contain {
            width: 800px;
            margin-top: 100px;
        }

        .option {
            margin-top: 47px;
            .optionTitle {
                font-family: PingFangSC, PingFang SC;
                font-weight: 500;
                font-size: 30px;
                color: #131415;
                line-height: 42px;
                text-align: left;
                font-style: normal;
                margin-bottom: 24px;

                .optionIcon {
                   
                    margin-left: 10px;
                    cursor: pointer;
                    img {
                        width: 25px;
                        height: 25px;
                    }
                }
            }

            .optionSelector {
                width: 800px;
            }

            .optionInput {
                .textarea {
                    width: 760px;
                    height: 185px;
                    font-family: PingFang-SC, PingFang-SC;
                    font-weight: 400;
                    font-size: 24px;
                    color: #131415;
                    line-height: 37px;
                    text-align: left;
                    font-style: normal;
                    resize: none;
                }
            }
        }

        .footerRight {
            width: 320px;
            cursor: pointer;
            margin-top: 80px;
            padding: 16px;
            border-radius: 8px;
            background: rgba(47, 178, 85, 1);
            font-size: 25px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            .text {
                text-align: center;
            }

        }

    }

    .right {
        width: 1000px;
        height: 1100px;
        margin-left: 58px;
        background: #FFFFFF;
        border-radius: 8px;
        border: 1px solid #979797;


        .wrapperTitle {
            border-radius: 8px;
            padding: 23px 0px;
            background: rgba(224, 224, 224, 1);
            border-bottom: 1px solid rgba(151, 151, 151, 0.5);
            text-align: center;
            font-size: 30px;
            font-weight: 600;
            color: rgba(76, 76, 76, 1);
        }

        .wrapperContent {
            font-size: 30px;
            line-height: 44px;
            height: 954px;
            padding: 25px 66px 30px 50px;
            overflow-y: scroll;
        }
       
    }

    .title {
        margin-left: 75px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(47, 178, 85, 1);
    }

    

}
