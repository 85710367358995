.wrapper {
    font-weight: 400;
    font-size: 20px;
    color: #4C4C4C;
    line-height: 28px;
}

.chatLeft {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
    .iconAndContent {
        display: flex;
        align-items: flex-start;
        .leftAvatar {
            flex-shrink: 0;
            width: 106px;
            height: 106px;
            .robot {
                width: 100%;
            }
            div{
                text-align: center;
                margin-top: -4px;
            }
        }
        .leftContent, .leftContentWithSmallMargin {
            // width: 1255px;
            margin-left: 30px;
            padding: 18px;
            border-radius: 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid #979797;
            white-space: pre-wrap;
            word-wrap: break-word;
        }
        .leftContentWithSmallMargin {
            margin-left: 30px;
            width: 420px;
        }
    }
    .like {
        margin-left: 20px;
        margin-right: 20px;
        width: 56px;
        // height: 56px;
        cursor: pointer;
        img {
            width: 100%;
        }
    }

    .btnWrapper {
        display: flex;
        justify-content: flex-end;
        .selectBtn {
            width: 200px;
            height: 68px;
            margin-top: 30px;
        }
    }
}

.chatRight {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    margin-top: 36px;
    .rightAvatar {
        width: 106px;
        height: 106px;
        .user {
            width: 100%;
        }
    }
    .rightContent, .rightContentWithSmallMargin {
        width: 955px;
        margin-right: 30px;
        padding: 18px;
        border-radius: 8px;
        background: rgba(255, 255, 255, 1);
        border: 1px solid #979797;
        white-space: pre-wrap;
        word-wrap: break-word;
        background: rgba(227, 227, 227, 1);
    }
    .rightContentWithSmallMargin {
        margin-right: 30px;
        width: 420px;
    }
}