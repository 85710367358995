body {
    background-color: rgba(247, 247, 247, 1);
}

.main {
    // position: fixed; 
    // position: static;

    width: 2306px;
    height: 1104px;
    margin: 0 auto;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #979797;

    .middle {
        font-family: 'Times New Roman', Times, serif;
        width: 2106px;
        margin-right: 39px;
        overflow-y: scroll;
        white-space: pre-wrap;
        padding: 40px 100px;
        height: 660px;
        font-size: 30px;
        line-height: 40px;
        flex-grow: 1;

        b {
            // font-weight: normal;
            background-color: yellow;
        }
    }

    .bottom {
        margin-top: 40px;
    }

    .noteWrapper {
        padding: 0 100px;
        position: relative;
        .title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-family: PingFang-SC, PingFang-SC;
            font-weight: 400;
            font-size: 32px;
            color: #131415;
            line-height: 45px;
            font-style: normal;

            img {
                width: 30px;
                margin-right: 19px;
            }
        }
        .note {
            margin-top: 12px;
            resize: none;
        }

        .btn {
            background-color: #2FB255;
            color: #FFFFFF;
            width: 309px;
            height: 80px;
            position: absolute;
            right: 120px;
            bottom: 20px;
        }
    
        .disable {
            background-color: #c7ebd3;
        }
    }
   

    .modal {
        text-align: center;

        .modalTitle {
            font-family: PingFangSC-Semibold;
            font-size: 40px;
            color: #343434;
            letter-spacing: 0;
            font-weight: 600;
        }
        
        .modalContent {
        text-align: left;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #343434;
        letter-spacing: 0;
        font-weight: 400;
        margin-top: 38px;
        }
        
        .modalBtnGroup {
        display: flex;
        justify-content: center;
        margin: 66px 0 40px 0;
        }
        
        .modalBtnOk, .modalBtnCancel {
        width: 225px;
        border: 1px solid #979797;
        border-radius: 8px;
        line-height: 70px;
        align-items: center;
        cursor: pointer;
        }
        
        .modalBtnOk {
        background: #2FB255;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #FFFFFF;
        letter-spacing: 0;
        font-weight: 400;
        }
        
        .modalBtnCancel {
        background: #FFFFFF;
        font-family: PingFangSC-Regular;
        font-size: 30px;
        color: #898989;
        letter-spacing: 0;
        text-align: center;
        font-weight: 400;
        margin-left: 50px;
        }
    }
      
      
}