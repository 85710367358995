.main {
    width: 1000px;
    height: 279px;
    background: #FFFFFF;
    border-radius: 8px;
    border: 1px solid #979797;
    cursor: pointer;
    padding: 30px 40px;
    margin: 0 16px 32px 16px;
    
}

.wrapper {
    position: relative;


    img {
        width: 228px;
    }

    .tag {
        position: absolute;
        bottom: 6px;
        left: 58px;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;
        font-size: 30px;
        color: #FFFFFF;
        line-height: 42px;
        text-align: left;
        font-style: normal;
    }
}

.mainAc {
    width: 996px;
    height: 275px;
    box-shadow: 0px 0px 20px 0px rgba(47,178,85,0.55);
    border: 4px solid #2FB255;
}

.example {
    font-family: PingFang-SC, PingFang-SC;
    font-weight: 400;
    font-size: 28px;
    color: #282828;
    line-height: 40px;
    text-align: left;
    font-style: normal;
    margin-top: 12px;
}