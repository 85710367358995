.main {
    width: 600px;
    height: 600px;
    background: #FFFFFF;
    box-shadow: 0px 0px 30px 0px rgba(47,178,85,0.42);
    border-radius: 35px;
    border: 4px solid #2FB255;
    margin: 0 50px;

    .wrapper {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-top: 50px;

        img {
            height: 300px;
            // width: 400px;
            max-width: 400px;
            // padding: 0 80px;
        }
        .title {
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size: 35px;
            color: #131415;
            line-height: 49px;
            font-style: normal;
            margin-top: 50px;
        }

        .btn {
            background-color: #2FB255;
            color: #FFFFFF;
            width: 309px;
            height: 80px;
            // line-height: 80px;
            position: relative;
            margin-top: 20px;

            .icon {
                position: absolute;
                right: 30px;
                top: 25px;
            }
        }
    }
}