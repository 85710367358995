body {
    background-color: rgba(247, 247, 247, 1);
}

.wrapper {
    // width: 100%;
    // display: flex;
    padding: 0 100px;
    

    .content {
        width: 100%;
        display: flex;
        // justify-content: center;
        // align-items: center;
        padding: 25px 0;

        background-color: #ffffff;
        border-radius: 28px;
        border: 1px solid rgba(151, 151, 151, 0.5);
        // margin-top: 100px;
    }

    .left {

        // width: 1043px;
        min-height: 1000px;
        border-right: 1px solid rgba(151, 151, 151, 0.5);
        flex-grow: 0;
        padding: 0 48px;

        .title {
            margin-top: 60px;
            margin-left: 20px;
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 30px;

        }

        .planRead {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;
            font-size: 35px;
        }

        .frequency {
            display: flex;
            justify-content: space-between;
        }
    }


    .right {

        .title {
            margin-top: 60px;
            margin-left: 20px;
            font-size: 40px;
            font-weight: 600;
            margin-bottom: 30px;

        }


    }
}

.bottom {
    margin-top: 30px;
    display: flex;
    margin-right: 100px;
    justify-content: flex-end;
}