body {
    background-color: rgba(247, 247, 247, 1);
}

.container {
    padding: 60px 120px;
    .title {
        margin-left: 75px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(47, 178, 85, 1);
    }

    .wrapper {
        margin-top: 55px;
        display: flex;
        justify-content: space-between;
        .wrapperLeft {
            padding: 20px 100px 45px 35px;
            background-color: #ffffff;
            .leftTitle {
                margin-left: 55px;
                display: flex;
                line-height: 44px;
                font-size: 30px;
                font-weight: 400;
                color: rgba(79, 79, 79, 1);

                .titleImg {
                    margin-left: 35px;
                    width: 23px;
                    // height: 23px;
                    cursor: pointer;
                    img {
                        width: 100%;
                    }
                }
            }
            .textarea {
                margin-top: 20px;
                margin-left: 55px;
                padding: 24px 34px;
                width: 1280px;
                // height: 203px;
                resize: none;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(151, 151, 151, 1);
                border-radius: 8px;
            }
            .textarea:focus {
                outline: none;
            }
            .table {
                margin-top: 50px;
                .tableTitle {
                    font-size: 30px;
                    font-weight: 400;
                    color: rgba(58, 58, 58, 1);
                    display: flex;
                    margin-left: 75px;
                    .titleDesc1 {
                        width: 240px;
                    }
                    .titleDesc2 {
                        width: 300px;
                    }
                    .hint {
                        font-size: 24px;
                    }
                }
                .reviewItems {
                    margin-top: 15px;
                }
            }
            .note {
                width: 1190px;
                padding: 30px 60px 60px 30px;
                margin: 60px 0px 0px 55px;
                background: rgba(255, 237, 207, 1);
                .noteTitle {
                    display: flex;
                    align-items: flex-end;
                    font-size: 50px;
                    font-weight: 600;
                    color: rgba(248, 220, 188, 1);
                    .noteIcon {
                        width: 56px;
                        // height: 56px;
                        margin-right: 30px;
                        img {
                            width: 100%;
                        }
                    }
                }
                .noteContent {
                    white-space: pre-wrap;
                    font-size: 25px;
                    font-weight: 400;
                    color: rgba(74, 74, 74, 1);
                    margin-top: 20px;
                }
            }
            
        }
        .wrapperRight {
            width: 740px;
            height: 1190px;
            overflow-y: scroll;
            border-radius: 8px;
            background: rgba(255, 255, 255, 1);
            border: 1px solid rgba(151, 151, 151, 1);
            .rightTitle {
                z-index: 100;
                font-size: 30px;
                font-weight: 600;
                color: rgba(76, 76, 76, 1);
                height: 90px;
                position: sticky;
                top: 0;
                border-radius: 8px 8px 0px 0px;
                background: rgba(224, 224, 224, 1);
                border-bottom: 1px solid rgba(151, 151, 151, 1);
                display: flex;
                align-items: center;
                .titleDesc {
                    flex: 1;
                    text-align: center;
                }
                .titleBtn {
                    margin-right: 15px;
                    height: 45px;
                    border-radius: 22.5px;
                    background: rgba(255, 255, 255, 1);
                    border: 1px solid rgba(151, 151, 151, 1);
                    display: flex;
                    padding: 0 15px;
                    .rightTitleButtonAlarm {
                        width: 22px;
                        // height: 25px;
                        cursor: pointer;
                        img {
                            width: 100%;
                        }
                    }
                    .rightTitleButtonDivider {
                        margin: 0 15px;
                        border: 0.1px solid rgba(151, 151, 151, 1);
                        height: 95%;
                    }
                    .rightTitleButtonLike {
                        width: 22px;
                        // height: 21px;
                        cursor: pointer;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
            .rightContent {
                padding: 25px;
            }
        }
    }

    .footer {
        margin: 25px 0 0 100px;
        width: 1380px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .footerLeft {
            font-size: 20px;
            font-weight: 400;
            letter-spacing: 0px;
            line-height: 28.96px;
            color: rgba(96, 96, 96, 1);
            .notice {
                margin-bottom: 6px;
            }
        }
        .footerRight {
            height: 80px;
            border-radius: 8px;
            background: rgba(47, 178, 85, 1);
            display: flex;
            align-items: center;
            font-size: 25px;
            font-weight: 400;
            color: rgba(255, 255, 255, 1);
            padding: 0 20px 0 30px;
            cursor: pointer;
            .buttonDivider {
                margin: 0 10px;
                border: 0.1px solid rgba(44, 163, 79, 1);
                height: 95%;
            }
            .buttonCoin {
                margin-left: 5px;
                width: 35px;
                height: 35px;
                img {
                    width: 100%;
                }
            }
        }
    }
}

.cntWord {
    color: rgba(43, 43, 43, 1)
}

.modal {

  .modalContent {
    margin-top: 30px;
    text-align: left;
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #343434;
    letter-spacing: 0;
    font-weight: 400;
  }

  .btnGroup {
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-top: 60px;
  }

  .modalConfirm, .modalCancel {
    border-radius: 8px;
    width: 225px;
    line-height: 71px;
    font-family: PingFangSC-Regular;
    font-size: 30px;
    color: #FFFFFF;
    letter-spacing: 0;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
  }

  .modalConfirm {
    background: #2FB255;
    border: 1px solid #979797;
  }
  
  .modalCancel {
    margin-left: 52px;
    background: #FFFFFF;
    color: #898989;
    border: 1px solid #979797;
  }
}