.wrapper {
    display: flex;
    flex-direction: column;
    // align-items: center;
    justify-content: center;
    // width: 100%;
    text-align: center;
    height: 400px;
    position: relative;
    cursor: pointer;

    width: 460px;
    border-radius: 8px;
    border: 1px solid rgba(151, 151, 151, 0.5);

    .subTitle {
        margin-top: 20px;
        margin-bottom: 40px;
        font-size: 27px;
        font-weight: 400;

    }

    .title {
        font-size: 35px;
        font-weight: 400;

    }

    .mins {
        font-size: 30px;
        font-weight: 400;

        display: flex;
        align-items: center;
        justify-content: center;
        .input {
            margin-right: 25px;
        }
    }

    .selected {
        width: 85px;
        height: 85px;
        position: absolute;
        right: 0;
        bottom: 0;
        
    }
}

.selectWrapper {
    border: 4px solid #1BB526;
    background-color: #F2FFF6;
}