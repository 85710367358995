.container {
    .item {
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        .deleteIcon {
            width: 40px;
            height: 40px;
            cursor: pointer;
            img {
                width: 100%;
            }
        }
        .operationContainer {
            padding: 10px 15px 10px 10px;
            width: 1250px;
            margin-left: 15px;
            border-radius: 8px;
            background: rgba(215, 215, 215, 1);
            border: 1px solid rgba(151, 151, 151, 1);
            display: flex;
            align-items: center;
            .button {
                width: 230px;
                height: 50px;
                line-height: 50px;
                // display: flex;
                // justify-content: center;
                // align-items: center;
                padding-left: 8px;
                border-radius: 8px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(151, 151, 151, 1);
                margin-right: 10px;
                font-size: 25px;
                font-weight: 400;
                color: rgba(68, 68, 68, 1);
                white-space: nowrap;
                word-break: keep-all;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .input {
                border-radius: 8px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(151, 151, 151, 1);
                flex: 1;
                padding-left: 24px;
                font-size: 25px;
                font-weight: 400;
                height: 50px;
                display: flex;
                align-items: center;
                color: rgba(68, 68, 68, 1);
                input {
                    outline: none;
                    border: none;
                    border-radius: 8px;
                    font-size: 25px;
                    width: 100%;
                }
            }

            .text {
                width: 600px;
                flex-grow: 0;
                border-radius: 8px;
                background: rgba(255, 255, 255, 1);
                border: 1px solid rgba(151, 151, 151, 1);
            }
        }
        .coinContainer {
            display: flex;
            align-items: center;
            margin-left: 20px;
            font-size: 20px;
            font-weight: 400;
            color: rgba(176, 109, 36, 1);
            .coinIcon {
                width: 35px;
                // height: 35px;
                margin-left: 10px;
                img {
                    width: 100%;
                }
            }
        }
    }
}
