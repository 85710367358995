.myAccount {
  display: flex;
}

.left {
  width: 50%;
  padding-left: 224px;
  padding-top: 148px;
}

.leftLogo {
  width: 506px;
}

.contentImg {
  display: block;
  margin-top: 62px;
  width: 677.74px;
  margin-left: 270px;
}

.right {
  width: 50%;
  flex: 1;
  position: relative;
}

.loginGuide {
  width: 238.77px;
  transform: rotate(1deg);
  position: absolute;
  margin-left: 471px;
  margin-top: 134.31px;
}

.rightBox {
  width: 753px;
  margin-top: 183px;
  padding:71px 0px;
  background: #FFFFFF;
  border: 1px solid #EEEEEE;
  box-shadow: 0 3px 20px 10px rgba(0,0,0,0.09);
  border-radius: 15px;
}

.displayContent {
  margin-left: 80px;
  font-family: ArialMT;
  font-size: 30px;
  color: #8B8B8B;
  letter-spacing: 0;
  font-weight: 400;
}

.bold {
  font-family: PingFangSC-Semibold;
}

.gender, .class, .email {
  margin-top: 26px;
}

.infoTitle {
  font-family: Arial-BoldMT;
  font-size: 50px;
  color: #4C4C4C;
  letter-spacing: 0;
  font-weight: 700;
}

.infoContent {
  margin: 46px 0 36px 0;

}

.divider {
  border: 0.1px solid #979797;
  width: 630px;
  margin: 0px 61px;
}

.inputContent {
  padding: 0px 80px;
}

.inputTitle {
  margin: 41px 0px 29px 0px;
  font-family: Arial-BoldMT;
  font-size: 35px;
  color: #4C4C4C;
  letter-spacing: 0;
  font-weight: 700;
}

.inputBox {
  display: flex;
  flex-flow: row nowrap;
  background: #F5F5F5;
  border: 1px solid #D7D7D7;
  border-radius: 15px;
  margin-bottom: 20px;
  padding-left: 30px;
}

.inputDesc {
  font-family: ArialMT;
  font-size: 30px;
  color: #585858;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: 400;
}

.inputCurrent, .inputNew, .inputConfirm {
  font-family: ArialMT;
  font-size: 25px;
  color: #B2B2B2;
  background-color: #F5F5F5;
  border-style: none;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: 400;
}

.inputCurrent {
  width: 260px;
}

.inputNew {
  width: 300px;
}

.inputConfirm {
  width: 200px;
}

.symbol {
  font-family: ArialMT;
  font-size: 30px;
  color: #E02020;
  letter-spacing: 0;
  line-height: 34px;
  font-weight: 400;
  margin-right: 5px;
}

input:focus {
  outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.rightButton {
  display: flex;
  flex-wrap: nowrap;
  padding: 28px 129px 0px 136px;
}

.change, .cancel, .disabled {
  border-radius: 10px;
  width: 224px;
  line-height: 80px;
  font-family: PingFangSC-Regular;
  font-size: 30px;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  cursor: pointer;
}

.cancel {
  background: #FFFFFF;
  margin-left: 40px;
  color: #AAAAAA;
  border: 1px solid #979797;
}

.change {
  background: #2FB255;
  color: #FFFFFF;
}

.disabled{
  background: #BAE4C2;
  color: #FFFFFF;
}

.modal {
  text-align: center;
}

.modalContent {
  text-align: left;
  font-family: PingFangSC-Regular;
  font-size: 30px;
  color: #343434;
  letter-spacing: 0;
  font-weight: 400;
  margin-top: 38px;
}
.modalBtnGroup {
  display: flex;
  justify-content: center;
  margin: 66px 0 40px 0;
}

.modalBtnOk, .modalBtnCancel {
  width: 225px;
  border: 1px solid #979797;
  border-radius: 8px;
  line-height: 70px;
  align-items: center;
  cursor: pointer;
}

.modalBtnOk {
  background: #2FB255;
  font-family: PingFangSC-Regular;
  font-size: 30px;
  color: #FFFFFF;
  letter-spacing: 0;
  font-weight: 400;
}

.modalBtnCancel {
  background: #FFFFFF;
  font-family: PingFangSC-Regular;
  font-size: 30px;
  color: #898989;
  letter-spacing: 0;
  text-align: center;
  font-weight: 400;
  margin-left: 50px;
}