.page {
    min-height: 100vh;

    .card {
        display: flex;
        justify-content: center;
        margin-top: 140px;
        
    }
}
