.group {
    display: flex;
    .item {
        margin-right: 35px;
    }

   
   
    .score {
        width: 145px;
        height: 25px;
        border-radius: 8px;
        border: 1px solid #979797;
        cursor: pointer;
    }

    .num {
        margin-top: 4px;
        text-align: center;
        color: #969696;
    }

    .active {
        .score {
            background-color: #2FB255;
            border: 1px solid #2FB255;
        }
        // .num {
        //     color: black
        // }

    }
}

.icon {
    margin-right: 8px;
    width: 31px;
    height: 31px;
    cursor: pointer;
}

