body {
    background-color: rgba(247, 247, 247, 1);
}

.container {
    padding: 60px 120px;
    .title {
        margin-left: 75px;
        font-size: 40px;
        font-weight: 600;
        color: rgba(47, 178, 85, 1);
    }

    .wrapper {
        margin-top: 55px;
        width: 100%;
        min-height: 1000px;
        background-color: #ffffff;
        border-radius: 8px;
        border: 1px solid rgba(151, 151, 151, 0.5);
        .wrapperTitle {
            border-radius: 8px;
            padding: 27px 0px;
            background: rgba(224, 224, 224, 1);
            border-bottom: 1px solid rgba(151, 151, 151, 0.5);
            text-align: center;
            font-size: 40px;
            font-weight: 600;
            color: rgba(76, 76, 76, 1);
        }
        .wrapperContent {
            font-size: 30px;
            line-height: 44px;
            height: 690px;
            padding: 25px 66px 30px 66px;
            overflow-y: scroll;
        }
        .wrapperFooter {
            border-radius: 8px;
            padding: 20px 290px 30px 290px;
            border-top: 1px solid rgba(151, 151, 151, 0.5);
            font-size: 40px;
            font-weight: 600;
            color: rgba(76, 76, 76, 1);
            display: flex;
            justify-content: space-between;
            align-items: center;
            .footerLeft {
                font-size: 30px;
                font-weight: 400;
                color: rgba(83, 83, 83, 1);
                .option {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    .optionIcon {
                        width: 25px;
                        height: 25px;
                        margin: 0 35px 10px 20px;
                        cursor: pointer;
                        img {
                            width: 100%;
                        }
                    }
                    .option3Content, .optionInput {
                        margin-left: 80px;
                    }

                    .textarea {
                        resize: none;
                        width: 700px;
                    }
                }
            }
            .footerRight {
                cursor: pointer;
                margin-top: 20px;
                padding: 25px 30px 25px 55px;
                border-radius: 8px;
                background: rgba(47, 178, 85, 1);
                display: flex;
                align-items: center;
                justify-content: space-between;
                font-size: 20px;
                font-weight: 400;
                color: rgba(255, 255, 255, 1);
                cursor: pointer;
                .text {
                    text-align: center;
                }
                .icon {
                    width: 42px;
                    // height: 42px;
                    margin-left: 20px;
                    img {
                        width: 100%;
                    }
                }
            }
        }
    }

}
.modalClose {
    width: 56px;
    // height: 56px;
    img {
        width: 100%;
    }
}
.modal {
    padding: 65px 75px 125px 75px;
    .modalTitle {
        font-size: 40px;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
        text-align: center;
        margin-bottom: 25px;
        a {
            font-size: 24px;
            text-decoration: underline;
            margin-left: 8px;
        }
    }
    .modalFooter {
        font-size: 30px;
        font-weight: 400;
        color: rgba(43, 43, 43, 1);
        text-align: center;
        margin-top: 25px;
    }
}

:global(.ant-table-thead > tr > th) {
    text-align: center !important;
    background: #E7E7E7 !important;
}
